export const environment = {
  production: true,
  hml: false,
  url: 'https://api.seliga.com.br/api/',
  keySamba: '1284cae8fdc86b22337e1f01eff815a0',
  idClienteSamba: '399',
  keySharpMarketing: 'KOI-47YI0Q6L5U',
  routeCache: [
    'https://api1.seliga.com.br/api/removeCache', // VM1
    'https://api2.seliga.com.br/api/removeCache' // VM2
  ]
};
