import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { HttpClient } from '../services/http.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router,
                private api: HttpClient) {}

    canActivate() {
        if (localStorage.getItem('user') && this.api.activateToken()) {
            return true;
        }

        this.router.navigate(['/login']);
        return false;
    }
}
