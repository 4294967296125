import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import baseUrl from './baseUrl';

import { User } from '../models';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {

    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(username: string, password: string) {
        // console.log(baseUrl.url + 'login', username, password);
        return this.http.post<any>(baseUrl.url + 'login', { email: username, password: password })
            .pipe(
                map(data => {
                    if (data && data.token) {
                      // store user details and jwt token in local storage to keep user logged in between page refreshes
                      localStorage.setItem('user', JSON.stringify(data));
                      this.currentUserSubject.next(data);
                    }
                    return data;
                }),
                catchError(err => {
                    console.log(err);
                    return err;
                })
            );
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('user');
        this.currentUserSubject.next(null);
    }
}
