import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { MatSnackBarComponent } from '../../layout/components/mat-snack-bar/mat-snack-bar.component';
import { Router } from '@angular/router';

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(private snackBar: MatSnackBarComponent, private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    console.log('request', request);
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        let mensagem = '';

        if (event instanceof HttpResponse) {
          if (event.status === 200 || event.status === 201) {
            if (
              event.body &&
              event.body.mensagem &&
              event.body.mensagem.length > 0 &&
              request.method !== 'GET'
            ) {
              mensagem = event.body.mensagem[0];
            }
          }
          if (event.status === 400) {
            if (
              event.body &&
              event.body.mensagem &&
              event.body.mensagem.length > 0
            ) {
              mensagem = event.body.mensagem[0];
            }
          }
          if (event.status === 403) {
            if (
              event.body &&
              event.body.mensagem &&
              event.body.mensagem.length > 0
            ) {
              mensagem = 'Acesso negado';
            }
          }
        }

        if (mensagem && !request.urlWithParams.includes('_no_feedback')) {
          this.snackBar.openSnackBar(mensagem, 'Fechar', 'default');
        }

        return event;
      }),
      catchError((err) => {
        console.log('err', err);

        let mensagem =
          'Algum problema ocorreu durante o processo. Por favor tente novamente.';

        if (
          err &&
          err.error &&
          err.error.mensagem &&
          err.error.mensagem.length > 0
        ) {
          mensagem =
            typeof err.error.mensagem === 'string'
              ? err.error.mensagem
              : err.error.mensagem[0];
        }

        if (err && err.mensagem && err.mensagem.length > 0) {
          mensagem =
            typeof err.mensagem === 'string' ? err.mensagem : err.mensagem[0];
        }

        if (err.status === 403) {
          mensagem = 'Acesso negado';
        }

        if (err.status === 400) {
        }

        this.snackBar.openSnackBar(mensagem, 'Fechar', 'default');

        if (err.status === 401) {
          localStorage.clear();
          this.router.navigateByUrl('/login');
          return throwError(null);
        }

        const error = err.error ? err.error.mensagem : err.statusText;
        return throwError(error);
      })
    );
  }
}
