import { LayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule, LOCALE_ID } from '@angular/core';
import {
    // MatButtonModule,
    // MatIconModule,
    // MatListModule,
    // MatSidenavModule,
    // MatToolbarModule,
    MatDialogModule,
    // MatProgressSpinnerModule,
    // MatTableModule,
    // MatPaginatorModule,
    // MatSortModule,
    MatPaginatorIntl,
    MatInputModule,
    MatFormFieldModule
} from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Interceptor } from './shared/services';
import { MatSnackBarComponent } from './layout/components/mat-snack-bar/mat-snack-bar.component';
import { MatSnackBarModule } from '@angular/material';

import { HttpModule } from '@angular/http';
import { getPtPaginatorIntl } from './shared/services/pt-paginator-intl';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgHttpLoaderModule } from 'ng-http-loader';

import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localePt, 'pt-BR');

export const createTranslateLoader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

// export const optionsMask: Partial<IConfig> | (() => Partial<IConfig>);

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        LayoutModule,
        OverlayModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        FormsModule,
        MatDialogModule,
        MatSnackBarModule,
        HttpModule,
        MatInputModule,
        MatDialogModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        NgHttpLoaderModule.forRoot(),
    ],
    providers: [MatSnackBarComponent,
                    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
                    { provide: MatPaginatorIntl, useValue: getPtPaginatorIntl() },
                    { provide: LOCALE_ID, useValue: 'pt-BR' },
                ],
    bootstrap: [AppComponent]
})
export class AppModule {}
