import { Injectable } from '@angular/core';
import { HttpClient as Http, HttpHeaders } from '@angular/common/http';
import baseUrl from './baseUrl';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { Http as Http2, ResponseContentType } from '@angular/http';
import { map } from 'rxjs/operators';

@Injectable(
    { providedIn: 'root' }
)

export class HttpClient {
    constructor(
        private http: Http,
        private router: Router,
        private http2: Http2,
    ) {}

    createAuthorizationHeader(headers: HttpHeaders, method: string): HttpHeaders {
        const user = JSON.parse(window.localStorage.getItem('user'));
        const token = user ? user.token : null;
        if (token) {
            headers = headers.append('Authorization', `Bearer ${token}`);
        }
        return headers;
    }

    get(url: string) {
        this.validateToken();
        let params = new HttpHeaders();
        params = params.append('Content-Type', 'application/json');
        const headers: HttpHeaders = this.createAuthorizationHeader(params, 'GET');
        return this.http.get(baseUrl.url + url, { headers });
    }

    post(url: string, data: any) {
        let params = new HttpHeaders();
        params = params.append('Content-Type', 'application/json');
        const headers: HttpHeaders = this.createAuthorizationHeader(params, 'POST');
        return this.http.post(baseUrl.url + url, data, {headers});
    }

    upload(url: string, data: FormData, isUpdate: Boolean = false) {
      const params = new HttpHeaders();
      // params = params.append('Content-Type', null);
      const method = !isUpdate ? 'POST' : 'PUT';
      const headers: HttpHeaders = this.createAuthorizationHeader(params, method);
      if (isUpdate) {
        data.append('_method', 'PUT');
      }
      return this.http.post(baseUrl.url + url, data, {headers});
  }

    put(url: string, data: any) {
        let params = new HttpHeaders();
        params = params.append('Content-Type', 'application/json');
        const headers: HttpHeaders = this.createAuthorizationHeader(params, 'PUT');
        return this.http.put(baseUrl.url + url, data, {headers});
    }

    delete(url: string) {
        let params = new HttpHeaders();
        params = params.append('Content-Type', 'application/json');
        const headers: HttpHeaders = this.createAuthorizationHeader(params, 'DELETE');
        return this.http.delete(baseUrl.url + url, {headers});
    }

    download(url: string) {
      if (url.length === 0) {
        return;
      }
      const user = JSON.parse(window.localStorage.getItem('user'));

      window.open(baseUrl.url + url + '&usr=' + user.user.id);
    }

    downloadFile(url: any) {
        return this.http2
          .get(baseUrl.url + url, {
            responseType: ResponseContentType.Blob,
            search: url
          })
        .pipe(
            map(res => {
                return {
                filename: 'filename.csv',
                data: res.blob()
                };
            })
        )
        .subscribe((res: any) => {
            console.log('start download:', res);
            const url = window.URL.createObjectURL(res.data);
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = res.filename;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove(); // remove the element
        }, error => {
            console.log('download error:', JSON.stringify(error));
        }, () => {
            console.log('Completed file download.');
        });
    }

    validateToken() {
        try {
            const helper = new JwtHelperService();
            let data: any = [];

            data = localStorage.getItem('user');
            data = JSON.parse(data);
            if (data) {
                const isExpired = helper.isTokenExpired(data.token);
                // const decodedToken = helper.decodeToken(data.token);
                // const expirationDate = helper.getTokenExpirationDate(data.token);
                if (isExpired) {
                    this.router.navigateByUrl('/login');
                }
            }
        } catch (err) {
            this.router.navigateByUrl('/login');
            console.log('err', err);
        }
    }

    getPageOrder(route: string) {
        const rotaAtiva = route;
        if (!rotaAtiva) {
          return null;
        }
        return localStorage.getItem(rotaAtiva.split('?')[0]);
    }

    setPageOrder(route: string, page: number, order: string, direction: string, size: number) {
        const rotaAtiva = route;
        if (!rotaAtiva) {
          return null;
        }
        return localStorage.setItem(rotaAtiva.split('?')[0], JSON.stringify({ordem: order, page: page, direction: direction, size: size}));
    }

    activateToken() {
        try {
            const helper = new JwtHelperService();
            let data: any = [];

            data = localStorage.getItem('user');
            data = JSON.parse(data);
            if (data) {

                const isExpired = helper.isTokenExpired(data.token);
                const decodedToken = helper.decodeToken(data.token);
                const expirationDate = helper.getTokenExpirationDate(data.token);
                if (isExpired) {
                    localStorage.removeItem('user');
                    return false;
                }

                return true;
            }

            return false;
        } catch (err) {

            return false;
            // this.router.navigateByUrl('/login');
            // console.log('err', err);
        }
    }

    postFullUrl(url: string, data: any) {
        let params = new HttpHeaders();
        params = params.append('Content-Type', 'application/json');
        const headers: HttpHeaders = this.createAuthorizationHeader(params, 'POST');
        return this.http.post(url, data, {headers});
    }
}
